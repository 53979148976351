import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import BackgroundVideo from "../components/bg-video";
import Navbar from "../components/navbar";
import GridDivision from "../components/grid-division";
import Article from "../components/article";
import Footer from "../components/footer";

import "./divisions.css";

import articles from "../data/article";

function Page(){
	useEffect(() => {
		document.title = "Rizqullah Group | Division"
	}, []);

	return(
		<React.Fragment>
			<BackgroundVideo videoPlayRate={0.5} color="#000" opacity="0" />
			<section id="layout">
				<Navbar title="Division" />
				<section style = {{ paddingTop: '14vh', zIndex: -1 }}>
					<section id="division-messages" className="animFadeIn">
						<p>Rizqullah Group have 13 startup</p>
						<p>- The Best Bussines Development Partner for The Future-</p>
					</section>
					<GridDivision />
					{
						articles.map((obj, i) => {
							return <Article key={i} thumb={obj.thumb} logo={obj.logo} desription={obj.desription} link={obj.link} flip={i % 2 !== 1} />;
						})
					}
				</section>
				<section id="our-team-link">
					<Link to="/teams">- Our Team -</Link>
				</section>
				<Footer />
			</section>
		</React.Fragment>
	);
}

export default Page;
