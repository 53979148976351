import React from "react";
import {
	BrowserRouter,
	Routes,
	Route,
} from "react-router-dom";


import HomePage from "./pages/home";
import DivisionsPage from "./pages/divisions";
import TeamsPage from "./pages/teams";
import ContactPage from "./pages/contact";

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<HomePage />} />
				<Route path="/divisions" element={<DivisionsPage />} />
				<Route path="/teams" element={ <TeamsPage /> } />
				<Route path="/contact" element={ <ContactPage /> } />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
