import { Link } from "react-router-dom";

import "./grid-division.css";

import gridItems from "../data/grid";

function Component(){
	return(
		<section id="grid-division" className="animFadeIn">
			{
				gridItems.map((obj, i) => {
					return(
						<Link key={i} to="/divisions">
							<img src={obj.img} alt="logo" />
						</Link>
					);
				})
			}
		</section>
	);
}

export default Component;
