import React, { useEffect } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";

import BackgroundVideo from "../components/bg-video";
import Navbar from "../components/navbar";
import GridDivision from "../components/grid-division";
import Footer from "../components/footer";

import "./teams.css";

import logo from "./../assets/Rizqullah-Group.png";

import person0 from "../images/amral.jpg";
import person1 from "../images/lukman.jpg";
import person2 from "../images/gibran.jpg";

const persons = [
	{
		img: person0,
		name: "Amral Firdan",
		title: "Chief Creative Officer",
	},
	{
		img: person1,
		name: "Moch. Lukman Hakim",
		title: "Chief Executive Officer",
	},
	{
		img: person2,
		name: "Muhammad Zidan Khalil Gibran",
		title: "Chief Technology Officer",
	},
];

function Page(){
	useEffect(() => {
		document.title = "Rizqullah Group | Stake"
	}, []);

	return(
		<React.Fragment>
			<BackgroundVideo videoPlayRate={1} color="#000" opacity="0" />
			<section id="layout">
				<Navbar title="Team" />
				<section style = {{ paddingTop: '14vh', zIndex: -1 }}>
					<section id="team-messages">
						<p>Being trusted takes a lot of time, effort, and experience. <br /> Rizqullah Group as a holding company currently has 13 startups.</p>
						<p>We specialize in providing a wide range of business needs to support each other and grow together. With the vision of creating a business ecosystem to create an amazing impact.</p>
					</section>
					<AnimationOnScroll animateIn="animate__slideInUp" animateOnce={true}>
						<h3 className="team-heading">The Chief</h3>
					</AnimationOnScroll>
					<section id="grid-person">
						{
							persons.map((obj, i) => {
								return(
									<section key={i}>
										<section className="team-person-wrapper">
											<section className="team-person-img-wrapper">
												<AnimationOnScroll animateIn="animate__slideInUp" animateOnce={true}>
													<img src={obj.img} alt="person" />
												</AnimationOnScroll>
											</section>
											<section className="team-person-title-wrapper">
												<AnimationOnScroll animateIn="animate__slideInUp" animateOnce={true}>
													<section className="team-person-title">
														{obj.title}
													</section>
												</AnimationOnScroll>
											</section>
										</section>
										<section className="team-person-name-wrapper">
											<section className="team-person-name">
												<AnimationOnScroll animateIn="animate__slideInRight" animateOnce={true}>
													{obj.name}
												</AnimationOnScroll>
											</section>
										</section>
									</section>
								);
							})
						}
					</section>
					<AnimationOnScroll animateIn="animate__slideInUp" animateOnce={true}>

						<h3 className="team-heading">The Team</h3>
					</AnimationOnScroll>
					<section id="team-logo-wrapper">
						<img src={logo} alt="logo" />
					</section>
					<GridDivision />
				</section>
				<section style={{height: "4rem"}}></section>
				<Footer />
			</section>
		</React.Fragment>
	);
}

export default Page;
