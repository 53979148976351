import imgLogo0 from "./../assets/milkty.png";
import imgLogo1 from "./../assets/travely.png";
import imgLogo2 from "./../assets/Varian 1.png";
import imgLogo3 from "./../assets/smartschool.png";
import imgLogo4 from "./../assets/gluxent.png";
import imgLogo5 from "./../assets/Sobat Difabel.png"; 
import imgLogo6 from "./../assets/Panel.png"; 
import imgLogo7 from "./../assets/collection.png"; 
import imgLogo8 from "./../assets/express.png"; 
import imgLogo9 from "./../assets/stickty.png"; 
import imgLogo10 from "./../assets/studio.png"; 
import imgLogo11 from "./../assets/adverstising.png"; 
import imgLogo12 from "./../assets/foundation.png"; 

const data = [
	{
		img: imgLogo0
	},
	{
		img: imgLogo1
	},
	{
		img: imgLogo2
	},
	{
		img: imgLogo3
	},
	{
		img: imgLogo4
	},
	{
		img: imgLogo5
	},
	{
		img: imgLogo6
	},
	{
		img: imgLogo7
	},
	{
		img: imgLogo8
	},
	{
		img: imgLogo9
	},
	{
		img: imgLogo10
	},
	{
		img: imgLogo11
	},
	{
		img: imgLogo12
	},

];

export default data;
