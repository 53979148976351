import { AnimationOnScroll } from "react-animation-on-scroll";

import "./article.css";

interface Props {
	thumb: any,
	logo: any,
	desription: string,
	link: string,
	flip: boolean,
}

function Component(props: Props){
	return(
		<article className={`article ${props.flip ? "flip" : ""}`}>
			<section className="article-thumb-wrapper">
				<AnimationOnScroll animateIn="animate__slideInUp" animateOnce={true}>
					<img src={props.thumb} alt="thumb" className="article-thumb" />
				</AnimationOnScroll>
			</section>
			<section className="article-body">
				<header>
					<AnimationOnScroll animateIn="animate__slideInUp" animateOnce={true}>
						<a href={props.link}>
							<img src={props.logo} alt="logo" />
						</a>
					</AnimationOnScroll>
				</header>
				<AnimationOnScroll animateIn="animate__slideInUp" animateOnce={true}>

					<p>{props.desription}</p>
				</AnimationOnScroll>
				<section className="see-more">
					<AnimationOnScroll animateIn="animate__slideInUp" animateOnce={true}>

						<a href={props.link}>See More</a>
					</AnimationOnScroll>
				</section>
			</section>
		</article>
	);
}

export default Component;
