import React, { useEffect } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";

import BackgroundVideo from "../components/bg-video";
import Navbar from "../components/navbar";
import Footer from "../components/footer";

import "./contact.css";

function Page(){
	useEffect(() => {
		document.title = "Rizqullah Group | Contact"
	}, []);

	return(
		<React.Fragment>
			<BackgroundVideo videoPlayRate={1} color="#000" opacity="0" />
			<section id="layout">
				<Navbar title="Contact" />
				<section style = {{ paddingTop: '14vh', zIndex: 1 }} id="contact-wrapper">
				<section id="contact-main">
					<section className="overflow-hidden">
						<AnimationOnScroll animateIn="animate__slideInLeft" animateOnce={true}>
							For requests, information or comments, please contact below.
						</AnimationOnScroll>
					</section>
					<section id="contact-grid">
						<section className="overflow-hidden">
							<AnimationOnScroll animateIn="animate__slideInLeft" animateOnce={true}>
								<h3 className="contact-heading">IT Consultant</h3>
								<p>Amral</p>
								<p>amral@lionofinformatics.net</p>
								<div className="spacer" />
								<p>Gibran</p>
								<p>gibran@lionofinformatics.net</p>
							</AnimationOnScroll>
						</section>
						<section className="overflow-hidden">
							<AnimationOnScroll animateIn="animate__slideInLeft" animateOnce={true}>
								<h3 className="contact-heading">Bussines Consultant</h3>
								<p>Luqman</p>
								<p>mochluxman@rizqullah.co</p>
							</AnimationOnScroll>
						</section>
						<section className="overflow-hidden">
							<AnimationOnScroll animateIn="animate__slideInLeft" animateOnce={true}>
								<h3 className="contact-heading">Marketing Consultant</h3>
								<p>Komerce</p>
								<p>komerce@rizqullah.co</p>
								{/* <div className="spacer" />
								<p>Aldi</p>
								<p>aldirhp@akuwedding.com</p> */}
								{/* <div className="spacer" />
								<p>Rika</p>
								<p>rika@akuwedding.com</p> */}
							</AnimationOnScroll>
						</section>
						<section className="overflow-hidden">
							<AnimationOnScroll animateIn="animate__slideInLeft" animateOnce={true}>
								<h3 className="contact-heading">Financial Consultant</h3>
								<p>Seza</p>
								<p>seza@rizqullah.co</p>
							</AnimationOnScroll>
						</section>
						<section className="overflow-hidden">
							<AnimationOnScroll animateIn="animate__slideInLeft" animateOnce={true}>
								<h3 className="contact-heading">HR/GA</h3>
								<p>talenta</p>
								<p>talenta@rizqullah.co</p>
							</AnimationOnScroll>
						</section>
					</section>
					<section id="contact-address">
						<section id="contact-address-text" className="overflow-hidden">
							<AnimationOnScroll animateIn="animate__slideInLeft" animateOnce={true}>
							<p>official@rizqullah.co</p>
							<p>Tel: (+62) 821 4057 2544</p>
							<div className="spacer"/>
							<p>Rizqullah Headquarters (HQ)</p>
							<p>Jl. Sumber RT. 002 / RW 004 Ds Panggungrejo Kec. Kepanjen Kab. Malang(65163)</p>
							</AnimationOnScroll>
						</section>
						<section>
							<div style={{width: "100%"}}>
								<iframe scrolling="no" title="maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3949.5848438562725!2d112.55652291533954!3d-8.143675883809838!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e789ff8df751d55%3A0x3f1c24afb774b6dc!2sRizqullah%20Corporation!5e0!3m2!1sid!2sid!4v1637776968206!5m2!1sid!2sid" width="100%" height="300" frameBorder="0"></iframe>
							</div>
						</section>
					</section>
				</section>
				</section>
				<Footer />
			</section>
		</React.Fragment>
	);
}

export default Page;
