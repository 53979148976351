import React, { useState } from "react";
import { Link } from "react-router-dom";

import "./navbar.css";

import akusaragroup from "./../assets/Fix-Logo.png";

interface Props {
  title: string;
}

function Component(props: Props){
	const [ showDrawer, setShowDrawer ] = useState(false);

	const navbarToggleDrawerHandler = (ev: React.MouseEvent<HTMLButtonElement>) => {
		ev.preventDefault();
		setShowDrawer(!showDrawer);
	};

	return(
		<React.Fragment>
			<section id="navbar-drawer-wrapper">
				<section id="navbar-drawer" className={`${showDrawer ? "open" : ""}`}>
					<button id="navbar-button-close" onClick={navbarToggleDrawerHandler}>
						<svg preserveAspectRatio="xMidYMid meet" data-bbox="25.9 25.9 148.2 148.2" xmlns="http://www.w3.org/2000/svg" viewBox="25.9 25.9 148.2 148.2" role="presentation" aria-hidden="true">
							<g fill="currentColor">
								<path d="M171.3 158.4L113 100l58.4-58.4c3.6-3.6 3.6-9.4 0-13s-9.4-3.6-13 0L100 87 41.6 28.7c-3.6-3.6-9.4-3.6-13 0s-3.6 9.4 0 13L87 100l-58.4 58.4c-3.6 3.6-3.6 9.4 0 13s9.4 3.6 13 0L100 113l58.4 58.4c3.6 3.6 9.4 3.6 13 0s3.5-9.5-.1-13z"></path>
							</g>
						</svg>
					</button>
					<ul id="navbar-drawer-list-link">
						<li><Link to="/">Company</Link></li>
						<li><Link to="/divisions">Division</Link></li>
						<li><Link to="/teams">Team</Link></li>
						<li><Link to="/contact">Contact</Link></li>
					</ul>
				</section>
			</section>
			<section id="navbar">
				<section id="navbar-button-wrapper">
					<button id="navbar-button-open" onClick={navbarToggleDrawerHandler}>
						<svg preserveAspectRatio="none" data-bbox="20 49 160 102" viewBox="20 49 160 102" height="200" width="200" xmlns="http://www.w3.org/2000/svg" data-type="shape" role="presentation" aria-hidden="true">
							<g fill="currentColor">
								<path d="M180 138.25V151H20v-12.75h160z"></path>
        							<path d="M180 49v12.75H20V49h160z"></path>
        							<path d="M180 93.625v12.75H20v-12.75h160z"></path>
							</g>
						</svg>
					</button>
					<span>
						{props.title}
					</span>
				</section>
				<Link to="/">
					<img src={ akusaragroup } id="logo" alt="akusara group logo" />
				</Link>
			</section>
		</React.Fragment>
	);
}

export default Component;
