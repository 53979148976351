
import "./footer.css";

function Component(){
	return(
		<footer>
			Copyright © {(new Date()).getFullYear()} PT. Rizqullah Indonesia Sejahtera. All Rights Reserved 
		</footer>
	);
}

export default Component;
