import imgLogo0 from "./../assets/milkty.png";
import imgLogo1 from "./../assets/travely.png";
import imgLogo2 from "./../assets/Varian 1.png";
import imgLogo3 from "./../assets/smartschool.png";
import imgLogo4 from "./../assets/gluxent.png";
import imgLogo5 from "./../assets/Sobat Difabel.png"; 
import imgLogo6 from "./../assets/Panel.png"; 
import imgLogo7 from "./../assets/collection.png"; 
import imgLogo8 from "./../assets/express.png"; 
import imgLogo9 from "./../assets/stickty.png"; 
import imgLogo10 from "./../assets/studio.png"; 
import imgLogo11 from "./../assets/adverstising.png"; 
import imgLogo12 from "./../assets/foundation.png"; 

import imgThumb0 from "./../assets/space-lionofinformatics.jpg";
import imgThumb1 from "./../assets/space-smartschool.jpg";
import imgThumb2 from "./../assets/space-travely.jpg";
import imgThumb3 from "./../assets/space-gluxent.jpg";
import imgThumb4 from "./../assets/space-milkty.jpg";

const data = [
	{
		logo: imgLogo2,
		thumb: imgThumb0,
		desription: "Lion Of Informatics is a startup that aims to develop a technology ecosystem in Indonesia. We Are provides various products & services for the needs of companies / startups. create a community and informatics education to realize Indonesia as a digital country.",
		link: "//lionofinformatics.net",
	},
	{
		logo: imgLogo3,
		thumb: imgThumb1,
		desription: "Smart School is a One Stop Digitize School. We provide support in all sectors in turning schools into Smart Schools in one stop digitize. capable of being integrated with IoT devices to provide effective solutions for teachers, students, and parents.",
		link: "//smartschool.id",
	},
	{
		logo: imgLogo1,
		thumb: imgThumb2,
		desription: "Travely is a One Stop Traveler Platform. which provides access to find information and book various accommodation services for holiday needs. Tourist attractions information, weather forecast, ticket booking, outdoor equipment rental, tour guide, transportation. Travely is also integrated with BMKG for warning of potential disasters.",
		link: "//travely.co",
	},
	{
		logo: imgLogo4,
		thumb: imgThumb3,
		desription: "Gluxent is the One Stop Men's Grooming Solution. Providing various kinds of appearance support products for men ranging from apparel to cosmetics (hair care) to make them look glamorous, luxurious and elegant as a gantleman.",
		link: "//gluxent.co",
	},
	{
		logo: imgLogo0	,
		thumb: imgThumb4,
		desription: "Milkty serves food and baverage that can be ordered online along with knowing the benefits and nutritional value, this is very useful for those of you who are undergoing a healthy program. Milkty has a menu customize feature for customers so they can feel the sensation of digitally making food according to their taste.",
		link: "//milkty.co",
	},
];

export default data;
