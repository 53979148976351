import { SyntheticEvent, useState } from "react";

import "./bg-video.css";

import imgBg from "../assets/bg.png";

interface Props {
	videoPlayRate: number,
	color: string,
	opacity: string,
}

function Component(props: Props){
	const [ hidePlaceHolder, setHidePlaceHolder ] = useState(false);

	// const videoOnLoadHandler = (ev: SyntheticEvent<HTMLElement>) => {
	// 	setHidePlaceHolder(true);
	// 	const videoElement = ev.target as HTMLVideoElement;
	// 	videoElement.playbackRate = props.videoPlayRate;
	// };

	return(
		<section id="background-video">
			<section id="background-video-wrapper">
				<img src={imgBg} alt="background" className={hidePlaceHolder ? "animFadeOut": ""} />
				<section id="background-video-overlay" style={{background: props.color, opacity: props.opacity}}></section>
			</section>
		</section>
	);
}

export default Component;
